import { useTranslate } from 'react-admin'
import { makeStyles, Typography } from '@material-ui/core'
import KlisstListIcon from '@material-ui/icons/ListAlt'
import UserListIcon from '@material-ui/icons/PeopleOutline'
import ItemListIcon from '@material-ui/icons/LibraryBooks'
import DashboardCounter from './DashboardCounter'
import { DashboardTopKlisstList, DashboardTopUserList } from './DashboardTops'

const useStyles = makeStyles((theme) => {
  return {
    flexSection: {
      margin: '2em 0',
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
      },
    },
    counterRow: {
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        marginLeft: '.5em',
        display: 'block',
      },
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    leftCol: {
      flex: 1,
      marginRight: '0.5em',
    },
    rightCol: {
      flex: 1,
      marginLeft: '0.5em',
    },
    singleCol: {
      marginTop: '1em',
      marginBottom: '1em',
    },
    sectionTitle: {
      margin: '1em 0 0.5em',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '0.5em',
      },
    },
  }
})
const Spacer = () => <div style={{ width: '1em', height: '1em' }} />

const Counters = () => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <>
      <Typography component="h3" variant="h5" className={classes.sectionTitle}>
        {translate('dashboard.counters.lastDayTitle')}
      </Typography>
      <div className={classes.counterRow}>
        <DashboardCounter
          id="lastDayKlissts"
          label="resources.klisst.name"
          linkTo="klisst"
          icon={KlisstListIcon}
        />
        <Spacer />
        <DashboardCounter
          id="lastDayUsers"
          label="resources.users.name"
          linkTo="users"
          icon={UserListIcon}
        />
        <Spacer />
        <DashboardCounter
          id="lastDayItems"
          label="resources.klisstItems.name"
          icon={ItemListIcon}
        />
        <Spacer />
        <DashboardCounter
          id="lastDayKlisstsWithNoItems"
          label="dashboard.counters.emptyKlissts"
          icon={KlisstListIcon}
        />
        <Spacer />
        <DashboardCounter
          id="lastDayUsersWhoDontPublish"
          label="dashboard.counters.emptyUsers"
          icon={UserListIcon}
        />
      </div>
      <Typography component="h3" variant="h5" className={classes.sectionTitle}>
        {translate('dashboard.counters.lastWeekTitle')}
      </Typography>
      <div className={classes.counterRow}>
        <DashboardCounter
          id="lastWeekKlissts"
          label="resources.klisst.name"
          linkTo="klisst"
          icon={KlisstListIcon}
        />
        <Spacer />
        <DashboardCounter
          id="lastWeekUsers"
          label="resources.users.name"
          linkTo="users"
          icon={UserListIcon}
        />
        <Spacer />
        <DashboardCounter
          id="lastWeekItems"
          label="resources.klisstItems.name"
          icon={ItemListIcon}
        />
        <Spacer />
        <DashboardCounter
          id="lastWeekKlisstsWithNoItems"
          label="dashboard.counters.emptyKlissts"
          icon={KlisstListIcon}
        />
        <Spacer />
        <DashboardCounter
          id="lastWeekUsersWhoDontPublish"
          label="dashboard.counters.emptyUsers"
          icon={UserListIcon}
        />
      </div>

      <Typography component="h3" variant="h5" className={classes.sectionTitle}>
        {translate('dashboard.counters.globalTitle')}
      </Typography>
      <div className={classes.counterRow}>
        <DashboardCounter
          id="totalKlissts"
          label="resources.klisst.name"
          linkTo="klisst"
          icon={KlisstListIcon}
        />
        <Spacer />
        <DashboardCounter
          id="totalUsers"
          label="resources.users.name"
          linkTo="users"
          icon={UserListIcon}
        />
        <Spacer />
        <DashboardCounter
          id="totalItems"
          label="resources.klisstItems.name"
          icon={ItemListIcon}
        />
        <Spacer />
        <DashboardCounter
          id="totalKlisstsWithNoItems"
          label="dashboard.counters.emptyKlissts"
          icon={KlisstListIcon}
        />
        <Spacer />
        <DashboardCounter
          id="totalUsersWhoDontPublish"
          label="dashboard.counters.emptyUsers"
          icon={UserListIcon}
        />
      </div>
    </>
  )
}

const Dashboard = () => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.flexSection}>
        <div className={classes.leftCol}>
          <Counters />
        </div>
      </div>
      <div className={classes.flexSection}>
        <DashboardTopUserList
          id="topKlisstPublishers"
          label="dashboard.tops.topKlisstPublishers"
          unit="dashboard.tops.klisstUnit"
        />
        <Spacer />
        <DashboardTopUserList
          id="topItemPublishers"
          label="dashboard.tops.topItemPublishers"
          unit="dashboard.tops.klisstItemUnit"
        />
        <Spacer />
        <DashboardTopUserList
          id="mostFollowedUsers"
          label="dashboard.tops.mostFollowedUsers"
          unit="dashboard.tops.followersUnit"
        />
      </div>
      <div className={classes.flexSection}>
        <DashboardTopKlisstList
          id="mostFollowedKlissts"
          label="dashboard.tops.mostFollowedKlissts"
          unit="dashboard.tops.followersUnit"
        />
        <Spacer />
        <DashboardTopKlisstList
          id="mostCompletedKlissts"
          label="dashboard.tops.mostCompletedKlissts"
          unit="dashboard.tops.klisstItemUnit"
        />
      </div>
    </>
  )
}

export default Dashboard
