import spanishMessages from '@blackbox-vision/ra-language-spanish'

const updatedMessages = spanishMessages.ra
updatedMessages.page.list = 'Listado de %{name}'
updatedMessages.notification.updated = 'Elemento actualizado'
updatedMessages.notification.deleted = 'Elemento borrado'
updatedMessages.page.dashboard = 'Inicio'
updatedMessages.page.edit = '%{name}: #%{id}'
updatedMessages.page.show = '%{name}: #%{id}'
updatedMessages.action.search = 'Buscar'
updatedMessages.action.export = 'Descargar'

export default {
  ra: updatedMessages,
  site: {
    title: 'Klisst',
  },
  resources: {
    users: {
      name: 'Usuarios',
      fields: {
        avatarUrl: 'Avatar',
        fullname: 'Nombre',
        username: 'Nombre de usuario',
        creationDate: 'Fecha de registro',
        firebaseId: 'Firebase id',
        isBlocked: 'Bloqueado',
      },
    },
    klisst: {
      name: 'Listas',
      fields: {
        name: 'Título',
        creationDate: 'Creado en',
        lastModifiedDate: 'Última modificación',
        user: 'Autor',
        visibility: 'Visibilidad',
        collaborative: 'Colaborativa?',
        createdAutomatically: 'Generada automáticamente?',
        collaborators: 'Colaboradores',
        followers: 'Seguidores',
      },
    },
    klisstItems: {
      name: 'Items',
    },
    klisstItemMetadata: {
      name: 'Autocompletado',
      fields: {
        title: 'Título',
        url: 'Url',
        description: 'Descripción',
        image: 'Imagen',
        queryPlaceholder:
          'Escribe tu búsqueda (o url para rainforest) y pulsa enter',
      },
    },
    klisstGeneration: {
      name: 'Generación automática',
      created: 'Nueva lista creada',
      createError: 'Error al crear lista',
      titleLabel: 'Título',
      usernameLabel: 'Username',
      contextLabel: 'Contexto',
      contextPlaceholder: 'para usar en la búsqueda de Google',
      instructionsLabel: 'Instrucciones para ChatGPT',
      typeChat: 'ChatGPT',
      typeChatExtra: 'ChatGPT + Google (url y fotos)',
      typeChatExtraWeb:
        'ChatGPT + Google (url y fotos) + website (descripción y fotos)',
    },
    klisstBulkGeneration: {
      name: 'Generación en masa',
      format:
        'El formato de cada línea ha de ser: "Título"\t"Descripción"\t"Username"\t"Nombre del usuario"\t"Contexto"',
      description: 'Descripción',
      descriptionLabel: 'Descripción',
      csvLabel: 'Copia el csv aquí',
      instructionsLabel: 'Instrucciones para ChatGPT',
      typeChatExtra: 'ChatGPT + Google (url y fotos)',
      typeChatExtraWeb:
        'ChatGPT + Google (url y fotos) + website (descripción y fotos)',
      error: 'Error al generar listas',
      successfulLists: 'En breves se crearán las listas',
      failureLists: 'Listas con errores',
    },
    management: {
      name: 'Gestión',
      refresh: {
        button: 'Refrescar properties de backend',
        updated: 'Properties actualizadas',
        nothingUpdated: 'Ninguna property ha cambiado',
      },
    },
  },
  dashboard: {
    counters: {
      emptyKlissts: 'Klissts vacios :(',
      emptyUsers: 'Usuarios que no han publicado :(',
      globalTitle: 'Total',
      lastDayTitle: 'Últimas 24 horas',
      lastWeekTitle: 'Última semana',
    },
    tops: {
      errorLoading: 'No se ha podido cargar la lista :(',
      followersUnit: 'seguidores',
      klisstUnit: 'klissts',
      klisstItemUnit: 'items',
      mostCompletedKlissts: 'Listas con más items',
      mostFollowedKlissts: 'Listas más seguidas',
      mostFollowedUsers: 'Usuarios más seguidos',
      topItemPublishers: 'Top Items',
      topKlisstPublishers: 'Top Klissters',
    },
  },
}
