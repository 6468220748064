import {
  Datagrid,
  Filter,
  ImageField,
  List,
  ListProps,
  SelectInput,
  TextField,
  UrlField,
  useQueryWithStore,
  useTranslate,
} from 'react-admin'
import SearchInputWithEnter from '../components/SearchInputWithEnter'

import * as React from 'react'

const KlisstMetadataFilter = (props: ListProps) => {
  // List of providers for item autocomplete
  const translate = useTranslate()
  const { loaded, error, data } = useQueryWithStore({
    type: 'getList',
    resource: 'klisstItemMetadata/providers',
    payload: {
      filter: undefined,
      pagination: { page: 0, perPage: 50 },
      sort: { field: 'none', order: 'asc' },
    },
  })
  const providers =
    !error && loaded
      ? data
      : [{ id: 'googleCustomSearch', name: 'googleCustomSearch' }]
  const placeholder = translate(
    'resources.klisstItemMetadata.fields.queryPlaceholder',
  )

  return (
    <Filter {...props}>
      <SelectInput
        label="Provider"
        source="provider"
        choices={providers}
        allowEmpty={false}
        alwaysOn
      />
      <SearchInputWithEnter
        label="Query"
        source="query"
        placeholder={placeholder}
        alwaysOn
      />
    </Filter>
  )
}

export const KlisstItemMetadataList = (props: ListProps) => (
  <List
    {...props}
    filters={<KlisstMetadataFilter />}
    filterDefaultValues={{ provider: 'googleCustomSearch' }}
    pagination={false}
    bulkActionButtons={false}
    exporter={false}
  >
    <Datagrid>
      <TextField source="provider" sortable={false} />
      <TextField source="title" sortable={false} />
      <UrlField source="url" sortable={false} target="_blank" />
      <TextField source="description" sortable={false} />
      <ImageField source="image" sortable={false} />
    </Datagrid>
  </List>
)
