import * as React from 'react'
import {
  useTranslate,
  SimpleForm,
  TextInput,
  Create,
  SelectInput,
  useNotify,
  useRefresh,
  useRedirect,
} from 'react-admin'
import { useState } from 'react'
import { KlisstBulkGeneration } from './KlisstBulkGenerationListing'

const SOURCE_CHAT = 'openAIChat'
const SOURCE_CHAT_EXTRA = 'openAIChatWithExtraMetadata'
const SOURCE_CHAT_EXTRA_WEB = 'openAIChatWithWebsiteMetadata'

const KlisstBulkGenerationCreate = () => {
  const t = useTranslate()
  const [response, setResponse] = useState<KlisstBulkGeneration | undefined>()
  const [error, setError] = useState<Error | undefined>()
  const formatMessage = t('resources.klisstBulkGeneration.format')
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const onSuccess = (response?: any) => {
    setResponse(response?.data)
    setError(undefined)
    notify('resources.klisstBulkGeneration.successfulLists', 'success', {
      count: response?.data.numberOfRequestedKlissts,
    })
    redirect('/klisstBulkGeneration')
    refresh()
  }

  const onFailure = (error?: any) => {
    setError(error)
    setResponse(undefined)
  }

  let result
  if (response) {
    result = (
      <div style={{ width: '100%' }}>
        <div style={{ color: 'green' }}>
          {`${t('resources.klisstBulkGeneration.successfulLists')}: ${
            response?.numberOfRequestedKlissts
          }`}
        </div>
        {response?.numberOfInvalidRequestedKlissts > 0 && (
          <div style={{ color: 'red' }}>
            {`${t('resources.klisstBulkGeneration.failureLists')}: ${
              response?.numberOfInvalidRequestedKlissts
            }`}
          </div>
        )}
      </div>
    )
  }
  if (error) {
    result = (
      <div style={{ color: 'red', width: '100%' }}>
        {t('resources.klisstBulkGeneration.error')}: {error?.message}
      </div>
    )
  }

  return (
    <Create
      title={t('resources.klisstBulkGeneration.name')}
      basePath="klisstBulkGeneration"
      resource="klisstBulkGeneration"
      onSuccess={onSuccess}
      onFailure={onFailure}
    >
      <SimpleForm mutationMode="pessimistic">
        <p style={{ width: '100%' }}>
          {t('resources.klisstBulkGeneration.description')}
        </p>
        <TextInput
          source="description"
          label={t('resources.klisstBulkGeneration.descriptionLabel')}
          isRequired={true}
          fullWidth={true}
          multiline={false}
        />
        <p style={{ width: '100%' }}>{formatMessage}</p>
        <TextInput
          source="bulkAsCSV"
          label={t('resources.klisstBulkGeneration.csvLabel')}
          isRequired={true}
          fullWidth={true}
          multiline={true}
        />
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <SelectInput
            source="source"
            choices={[
              {
                id: SOURCE_CHAT_EXTRA,
                name: 'resources.klisstBulkGeneration.typeChatExtra',
              },
              {
                id: SOURCE_CHAT_EXTRA_WEB,
                name: 'resources.klisstBulkGeneration.typeChatExtraWeb',
              },
              {
                id: SOURCE_CHAT,
                name: 'resources.klisstBulkGeneration.typeChat',
              },
            ]}
            label=""
            defaultValue={SOURCE_CHAT_EXTRA}
            style={{ width: '400px', marginRight: '20px' }}
          />
          <TextInput
            source="instructions"
            label={t('resources.klisstGeneration.instructionsLabel')}
            isRequired={false}
            style={{ width: '600px', marginRight: '20px' }}
          />
        </div>
        {result}
      </SimpleForm>
    </Create>
  )
}

export default KlisstBulkGenerationCreate
