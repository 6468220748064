import ViewListIcon from '@material-ui/icons/ViewList'
import UserListIcon from '@material-ui/icons/PeopleOutline'
import KlisstListIcon from '@material-ui/icons/ListAlt'
import KlisstItemMetadataIcon from '@material-ui/icons/FindInPageOutlined'
import KlisstGenerationIcon from '@material-ui/icons/AddCircleOutlined'
import KlisstBulkGenerationIcon from '@material-ui/icons/Layers'
import ManagementIcon from '@material-ui/icons/Build'
import { AvailablePermissions } from '../../klisstConfig/PermissionsService'
import {
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  usePermissions,
  useTranslate,
} from 'react-admin'

type MenuItem = {
  resource: string
  label?: string
  requiredPermissions: AvailablePermissions[]
  icon: JSX.Element
}

const items: MenuItem[] = [
  {
    resource: 'users',
    requiredPermissions: ['findUsers'],
    icon: <UserListIcon />,
  },
  {
    resource: 'klisst',
    requiredPermissions: ['findPublicKlissts', 'findPrivateKlissts'],
    icon: <KlisstListIcon />,
  },
  {
    resource: 'klisstItemMetadata',
    requiredPermissions: [],
    icon: <KlisstItemMetadataIcon />,
  },
  {
    resource: 'klisstGeneration',
    requiredPermissions: [],
    icon: <KlisstGenerationIcon />,
  },
  {
    resource: 'klisstBulkGeneration',
    requiredPermissions: [],
    icon: <KlisstBulkGenerationIcon />,
  },
  {
    resource: 'management',
    requiredPermissions: [],
    icon: <ManagementIcon />,
  },
]

const KlisstMenu = (props: MenuProps) => {
  const translate = useTranslate()
  const { permissions } = usePermissions()
  const getMenuLinkByItem = (item: MenuItem) => {
    if (!permissions?.isAllowedToAny(item.requiredPermissions)) {
      return null
    }
    return (
      // @ts-ignore
      <MenuItemLink
        key={item.resource}
        to={`/${item.resource}`}
        primaryText={translate(item.label || `resources.${item.resource}.name`)}
        leftIcon={item.icon || <ViewListIcon />}
        onClick={props.onMenuClick}
      />
    )
  }

  return (
    <div>
      <DashboardMenuItem />
      {items.map(getMenuLinkByItem)}
    </div>
  )
}

export default KlisstMenu
