import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/core/styles'
import { Klisst } from '../../types/api'
import { Typography } from '@material-ui/core'

type KlisstSummaryInListProps = {
  klisst: Klisst
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    flex: 1,
    margin: '0 0.5em 0 0',
    height: '55px',
    width: '55px',
  },
  content: {
    lineHeight: '0.6em',
  },
  title: {
    fontWeight: 'bold',
    maxWidth: '300px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  author: {
    color: theme.palette.primary.main,
  },
}))

const KlisstSummaryInList = ({ klisst }: KlisstSummaryInListProps) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Avatar
        src={klisst.items[0]?.image?.resource}
        className={classes.avatar}
      />
      <div className={classes.content}>
        <Typography className={classes.title}>{klisst.title}</Typography>
        <Typography className={classes.author}>
          @{klisst.user.handler}
        </Typography>
      </div>
    </div>
  )
}

export default KlisstSummaryInList
