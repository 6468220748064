import * as React from 'react'
import {
  TextInput,
  SelectInput,
  Filter,
  FunctionField,
  List,
  DeleteButton,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  ListProps,
  PaginationProps,
  Pagination,
  Record,
  ReferenceField,
  usePermissions,
} from 'react-admin'

import LinkToSectionField from '../custom/LinkToSectionField'
import ExternalLinkButton from '../custom/ExternalLinkButton'
import { DetailedKlisstItem, DetailedUser } from '../types/api'
import { Avatar, makeStyles } from '@material-ui/core'

const KlisstsFilter = (props: ListProps) => {
  const { permissions } = usePermissions()
  return (
    <Filter {...props}>
      <TextInput label="Username" source="username" alwaysOn />
      {permissions?.isAllowedTo('findPrivateKlissts') && (
        <SelectInput
          source="visibility"
          choices={[
            { id: 'Public', name: 'Public' },
            { id: 'Private', name: 'Private' },
          ]}
          alwaysOn
        />
      )}
      <TextInput label="PublicId" source="publicId" alwaysOn />
    </Filter>
  )
}

const KlisstPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
)

const useStyles = makeStyles(() => ({
  authors: {
    display: 'flex',
    alignItems: 'center',
  },
  avatars: {
    display: 'flex',
    marginLeft: '1em',
  },
  avatar: {
    height: '32px',
    width: '32px',
    border: '2px solid #FFF',
    '&:not(:last-child)': {
      marginRight: '-20px',
      opacity: 0.75,
    },
    '&:hover': {
      opacity: 1,
    },
  },
}))

type CollaboratorsProps = {
  collaborators: [DetailedUser]
  maxAvatars?: number
}
const Collaborators = ({
  collaborators,
  maxAvatars = 3,
}: CollaboratorsProps) => {
  const classes = useStyles()
  return (
    <div className={classes.authors}>
      <span>{collaborators.length}</span>
      <div className={classes.avatars}>
        {collaborators.slice(0, maxAvatars).map((user: DetailedUser) => (
          <Avatar
            key={user.id}
            src={user.avatarUrl}
            title={user.username}
            className={classes.avatar}
          />
        ))}
      </div>
    </div>
  )
}

export const KLisstList = (props: ListProps) => {
  const { permissions } = usePermissions()
  return (
    <List
      {...props}
      filters={<KlisstsFilter />}
      filter={{
        visibility: permissions?.isAllowedTo('findPrivateKlissts')
          ? null
          : 'Public',
      }}
      title="Klissts"
      sort={{ field: 'creationDate', order: 'DESC' }}
      pagination={<KlisstPagination />}
    >
      <Datagrid isRowSelectable={() => false}>
        <TextField source="id" />
        <TextField label="Name" source="title" />
        <DateField source="creationDate" />
        <DateField source="lastModifiedDate" />
        <LinkToSectionField
          basePath="klisst"
          label="Username"
          filterKey="username"
          filterValueSource="author.username"
        />
        <TextField source="visibility" />
        <BooleanField source="collaborative" sortBy="isCollaborative" />
        <BooleanField
          source="createdAutomatically"
          sortBy="createdAutomatically"
        />
        <FunctionField
          label="resources.klisst.fields.collaborators"
          render={(record?: Record) => {
            const uniqueAuthors = record?.items.reduce(
              (acc: [DetailedUser], item: DetailedKlisstItem) => {
                const author = item.author
                const authorAlreadyExists = acc.find(
                  (user: DetailedUser) => user.id == author.id,
                )
                if (authorAlreadyExists) {
                  return acc
                }
                return acc.concat(author)
              },
              [record.author],
            )
            return <Collaborators collaborators={uniqueAuthors} />
          }}
        />
        <ReferenceField
          reference="klisstFollowerDigest"
          source="id"
          sortable={false}
          label="resources.klisst.fields.followers"
          link={false}
        >
          <TextField source="numberOfFollowers" />
        </ReferenceField>
        <ExternalLinkButton linkSource="permalink" />
        {permissions?.isAllowedTo('deleteKlissts') && (
          <DeleteButton mutationMode="pessimistic" />
        )}
      </Datagrid>
    </List>
  )
}
