import * as React from 'react'
import { FC, createElement } from 'react'
import { Card, Box, Typography, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-admin'
import { ReactNode } from 'react'

interface Props {
  icon: FC<any>
  to: string
  title?: string
  subtitle?: string | number
  children?: ReactNode
}

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 52,
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },
    overflow: 'visible',
    marginTop: '1em',
  },
  main: {
    overflow: 'visible',
    padding: '0 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  icon: {
    background: theme.palette.primary.main,
    padding: '1em',
    borderRadius: '3px',
    position: 'relative',
    bottom: '12px',
    display: 'flex',
    justifyContent: 'center',
  },
  title: {
    fontSize: '1.2em',
    fontVariant: 'small-caps',
    textAlign: 'center',
    width: '100%',
  },
  counter: {
    fontSize: '3em',
    textAlign: 'center',
    flex: 2,
    marginBottom: '16px',
  },
}))

const KlisstCounterWIthIcon = (props: Props) => {
  const { icon, title, subtitle, to, children } = props
  const classes = useStyles(props)
  return (
    <Card className={classes.card}>
      <Link to={to}>
        <div className={classes.main}>
          <Typography className={classes.title} color="textSecondary">
            {title}
          </Typography>
          <Box width="3em" className={classes.icon}>
            {createElement(icon, { fontSize: 'large', color: 'secondary' })}
          </Box>
        </div>
        <Typography className={classes.counter} variant="h5" component="h2">
          {subtitle || ' '}
        </Typography>
      </Link>
      {children && <Divider />}
      {children}
    </Card>
  )
}

export default KlisstCounterWIthIcon
