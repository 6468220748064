import { useNotify, useTranslate } from 'react-admin'
import { Button } from '@material-ui/core'
import * as React from 'react'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { SERVER_URL } = require(`../../config/${
  process.env.REACT_APP_ENV || 'development'
}`)

const RefreshPropertiesButton = (props: any) => {
  const translate = useTranslate()
  const notify = useNotify()

  const refresh = async () => {
    try {
      const url = `${SERVER_URL}/management/refresh`
      const config = { method: 'POST' }
      const data = await fetch(url, config).then((r) => r.json())
      console.log(data)

      const message =
        data && data.length > 0
          ? `${translate('resources.management.refresh.updated')}: ${data.join(
              ', ',
            )}`
          : 'resources.management.refresh.nothingUpdated'
      notify(message)
    } catch (e: any) {
      console.log(e)
      notify(e)
    }
  }

  return (
    <Button color="primary" onClick={refresh} {...props}>
      {translate('resources.management.refresh.button')}
    </Button>
  )
}

export default RefreshPropertiesButton
