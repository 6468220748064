import * as React from 'react'
import { ListProps, Login, LoginForm } from 'react-admin'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from 'firebase/compat/app'

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: '#/',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    new firebase.auth.OAuthProvider('apple.com').providerId,
  ],
}

const SignInScreen = () => (
  <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
)

const CustomLoginForm = (props: ListProps) => (
  <div>
    <LoginForm {...props} />
    <SignInScreen />
  </div>
)

const CustomLoginPage = (props: ListProps) => (
  <Login {...props}>
    <CustomLoginForm {...props} />
  </Login>
)

export default CustomLoginPage
