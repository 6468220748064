import { useRecordContext } from 'react-admin'
import * as React from 'react'
import {
  InjectedFieldProps,
  PublicFieldProps,
} from 'ra-ui-materialui/src/field/types'
import Button from '@material-ui/core/Button'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { get } from 'lodash'
import { ReactElement } from 'react'

/**
 * Button with an external link
 * @param props
 * @constructor
 */

export interface ExternalLinkButtonProps
  extends PublicFieldProps,
    InjectedFieldProps {
  linkSource: string
  // "label" is automatically added as title of the column, and I wasn't able to override it
  linkLabel?: string
  icon?: ReactElement
}

const ExternalLinkButton = (props: ExternalLinkButtonProps) => {
  const {
    linkSource,
    linkLabel = 'open',
    icon = <OpenInNewIcon />,
    ...rest
  } = props
  const record = useRecordContext(props)

  const value = get(record, linkSource)
  return (
    <Button
      size="small"
      color="primary"
      // We need href or component + to for the link to work
      href={value}
      target="_blank"
      onClick={(e) => e.stopPropagation()}
      {...(rest as any)}
    >
      {icon} &nbsp; {linkLabel}
    </Button>
  )
}

export default ExternalLinkButton
