import { useQueryWithStore, useTranslate } from 'react-admin'
import { createElement, FC } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Card,
  CardHeader,
  LinearProgress,
  List,
  ListItem,
} from '@material-ui/core'
import UserSummaryInList from '../components/UserSummaryInList'
import KlisstSummaryInList from '../components/KlisstSummaryInList'

interface DashboardTopUserListProps {
  id: string
  label: string
  unit?: string
}

interface DashboardTopListProps extends DashboardTopUserListProps {
  resource: string
  component: FC<any>
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  row: {
    justifyContent: 'space-between',
  },
  title: {
    background: theme.palette.secondary.light,
  },
  counter: {
    color: theme.palette.primary.main,
    fontSize: '1.1em',
  },
  counterUnit: {
    marginLeft: '0.1em',
    color: theme.palette.primary.light,
  },
  errorLoading: {
    color: theme.palette.error.main,
    fontSize: '1.3em',
    lineHeight: '2em',
    textAlign: 'center',
    width: '100%',
  },
}))

const DashboardTopList = ({
  id,
  label,
  unit,
  resource,
  component,
}: DashboardTopListProps) => {
  const { loaded, error, data } = useQueryWithStore({
    type: 'getOne',
    resource: 'dashboard',
    payload: { id: id },
  })
  const classes = useStyles()
  const translate = useTranslate()

  const list = loaded ? (
    <List dense={true}>
      {(data?.top || []).map((record: any) => {
        const recordElement = record[resource] || { id: 'unknown' }
        const entityProps = { [resource]: recordElement }
        return (
          <ListItem key={recordElement.id} button className={classes.row}>
            {createElement(component, entityProps)}
            <p className={classes.counter}>
              {record.counter}
              <span className={classes.counterUnit}>
                {translate(unit || '')}
              </span>
            </p>
          </ListItem>
        )
      })}
    </List>
  ) : (
    <LinearProgress />
  )

  return (
    <Card className={classes.root}>
      <CardHeader title={translate(label)} className={classes.title} />
      {error ? (
        <p className={classes.errorLoading}>
          {translate('dashboard.tops.errorLoading')}
        </p>
      ) : (
        list
      )}
    </Card>
  )
}

export const DashboardTopUserList = (props: DashboardTopUserListProps) => {
  return (
    <DashboardTopList
      {...props}
      resource="user"
      component={UserSummaryInList}
    />
  )
}

export const DashboardTopKlisstList = (props: DashboardTopUserListProps) => {
  return (
    <DashboardTopList
      {...props}
      resource="klisst"
      component={KlisstSummaryInList}
    />
  )
}
