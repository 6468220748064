import { useRecordContext, Link } from 'react-admin'
import * as React from 'react'
import {
  InjectedFieldProps,
  PublicFieldProps,
} from 'ra-ui-materialui/src/field/types'
import { AnchorHTMLAttributes } from 'react'
import { stringify } from 'query-string'
import { get } from 'lodash'

/**
 * Adds an external link on top of a textual field
 * @param props
 * @constructor
 */

export interface LinkToSectionFieldProps
  extends PublicFieldProps,
    InjectedFieldProps,
    AnchorHTMLAttributes<HTMLAnchorElement> {
  basePath: string
  filterKey: string
  filterValueSource: string
}

const LinkToSectionField = (props: LinkToSectionFieldProps) => {
  const { basePath, filterKey, filterValueSource } = props
  const record = useRecordContext(props)
  const value = get(record, filterValueSource as any)
  return (
    <Link
      to={{
        pathname: basePath,
        search: stringify({
          page: 1,
          perPage: 25,
          filter: JSON.stringify({ [filterKey]: value }),
        }),
      }}
    >
      {value}
    </Link>
  )
}

export default LinkToSectionField
