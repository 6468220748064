import * as React from 'react'
import {
  TextInput,
  DateInput,
  Filter,
  List,
  Datagrid,
  TextField,
  DateField,
  BooleanField,
  ImageField,
  ListProps,
  Pagination,
  PaginationProps,
  DeleteButton,
  usePermissions,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import GoToKlisstsIcon from '@material-ui/icons/List'
import ExternalLinkButton from '../custom/ExternalLinkButton'
import LinkToSectionButton from '../custom/LinkToSectionButton'

const useStyles = makeStyles({
  image: {
    maxHeight: '2rem',
  },
})

const UserPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
)

const UsersFilter = (props: ListProps) => (
  <Filter {...props}>
    <TextInput label="Username" source="username" alwaysOn />
    <DateInput label="Creado desde" source="from" alwaysOn />
    <DateInput label="Creado hasta" source="to" alwaysOn />
  </Filter>
)

export const UserList = (props: ListProps) => {
  const { permissions } = usePermissions()
  return (
    <List
      {...props}
      filters={<UsersFilter />}
      title="Usuarios"
      sort={{ field: 'creationDate', order: 'DESC' }}
      pagination={<UserPagination />}
    >
      <Datagrid isRowSelectable={() => false}>
        <TextField source="id" />
        <ImageField source="avatarUrl" sortable={false} classes={useStyles()} />
        <TextField source="username" />
        <TextField source="fullname" />
        <DateField source="creationDate" />
        <TextField source="firebaseId" sortable={false} />
        <BooleanField source="isBlocked" />
        <LinkToSectionButton
          basePath="klisst"
          filterKey="username"
          filterValueSource="username"
          linkLabel="klissts"
          icon={<GoToKlisstsIcon />}
        />
        <ExternalLinkButton linkSource="permalink" />
        {permissions?.isAllowedTo('deleteUsers') && (
          <DeleteButton mutationMode="pessimistic" />
        )}
      </Datagrid>
    </List>
  )
}
