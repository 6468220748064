import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { loadConfig } from './loadConfigActions'

type ConfigLoaderProps = {
  loadConfig: () => any
}

const ConfigLoader = (props: ConfigLoaderProps) => {
  useEffect(() => {
    props.loadConfig()
    setInterval(() => {
      props.loadConfig()
    }, 60000)
  })
  return <span />
}

export default connect(null, { loadConfig })(ConfigLoader)
