import * as React from 'react'
import { Admin, Resource } from 'react-admin'
import { Route } from 'react-router-dom'
import { KlisstItemMetadataList } from './ui/items/KlisstItemMetadata'

import i18nProvider from './i18n'
import dataProvider from './http/DataProvider'
import authProvider from './http/AuthProvider'

import loadConfigReducer from './klisstConfig/loadConfigReducer'
import loadConfigSaga from './klisstConfig/loadConfigSaga'

import FirebaseLoginPage from './FirebaseLoginPage'
import { UserList } from './resource/UserListing'
import { KLisstList } from './resource/KlisstListing'
import ConfigLoader from './klisstConfig/ConfigLoader'
import KlisstMenu from './ui/layout/Menu'
import Dashboard from './ui/dashboard/Dashboard'
import Management from './ui/management/Management'
import theme from './ui/layout/KlisstTheme'
import KlisstLayout from './ui/layout/Layout'
import AutomaticKlisstGeneration from './ui/generation/AutomaticKlisstGeneration'
import KlisstBulkGenerationCreate from './resource/KlisstBulkGenerationCreate'
import { KlisstBulkGenerationList } from './resource/KlisstBulkGenerationListing'

const DEFAULT_LOCALE = 'es'
i18nProvider.changeLocale(DEFAULT_LOCALE)

const App = () => {
  // ts complains if we add this directly on the customRoutes array
  const managementRoute = <Route path="/management" component={Management} />
  const generationRoute = (
    <Route path="/klisstGeneration" component={AutomaticKlisstGeneration} />
  )

  return (
    <Admin
      i18nProvider={i18nProvider}
      authProvider={authProvider}
      dataProvider={dataProvider}
      loginPage={FirebaseLoginPage}
      customReducers={{
        klisstConfig: loadConfigReducer,
      }}
      customSagas={[loadConfigSaga]}
      menu={KlisstMenu}
      dashboard={Dashboard}
      theme={theme}
      layout={KlisstLayout}
      customRoutes={[managementRoute, generationRoute]}
    >
      <Resource key="users" name="users" list={UserList} />
      <Resource key="klissts" name="klisst" list={KLisstList} />
      <Resource
        key="klisstItemMetadata"
        name="klisstItemMetadata"
        list={KlisstItemMetadataList}
      />
      <Resource name="klisstFollowerDigest" />
      <Resource
        key="klisstBulkGeneration"
        name="klisstBulkGeneration"
        list={KlisstBulkGenerationList}
        create={KlisstBulkGenerationCreate}
      />
      <ConfigLoader key="loadConfig" />
    </Admin>
  )
}

export default App
