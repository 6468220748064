import { Field } from 'react-final-form'
import { TextField, IconButton, InputAdornment } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import Clear from '@material-ui/icons/Clear'
import { useState } from 'react'

const SearchInputWithEnter = (props: any) => {
  const [value, setValue] = useState('')
  const { label, source, placeholder } = props

  return (
    <span style={{ marginBottom: 8, width: '500px' }}>
      <Field name={source}>
        {(props) => {
          if (!value && props.input.value) {
            props.input.onChange('')
          }

          return (
            <div>
              <TextField
                fullWidth={true}
                placeholder={placeholder}
                name={props.input.name}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value ? (
                        <IconButton
                          onClick={() => {
                            setValue('')
                            props.input.onChange('')
                          }}
                        >
                          <Clear />
                        </IconButton>
                      ) : (
                        <SearchIcon />
                      )}
                    </InputAdornment>
                  ),
                }}
                value={value}
                onChange={(ev) => {
                  ev.persist()
                  setValue(ev.target.value)
                  if (!ev.target.value) {
                    props.input.onChange('')
                  }
                }}
                onKeyPress={(e) => {
                  e.persist()
                  if (e.key === 'Enter') {
                    props.input.onChange(value)
                  }
                  return null
                }}
                label={label}
              />
            </div>
          )
        }}
      </Field>
    </span>
  )
}

export default SearchInputWithEnter
