import {
  LOAD_KLISST_CONFIG_SUCCESS,
  LoadedConfigAction,
} from './loadConfigActions'

export default (previousState: object = {}, action: LoadedConfigAction) => {
  switch (action.type) {
    case LOAD_KLISST_CONFIG_SUCCESS:
      return { ...previousState, ...action.payload }
    default:
      return previousState
  }
}
