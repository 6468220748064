export type AvailablePermissions =
  | 'findPublicKlissts'
  | 'findPrivateKlissts'
  | 'findUsers'
  | 'deleteUsers'
  | 'createKlissts'
  | 'deleteKlissts'
  | 'createKlisstsOnBehalf'
  | 'moderateReportedContent'

export interface Permissions {
  isAllowedTo: (requestedPermission: AvailablePermissions) => boolean
  isAllowedToAny: (atLeastOnePermission: AvailablePermissions[]) => boolean
}

export const PermissionsForActions = (): Permissions => {
  const loadPermissions = () =>
    JSON.parse(localStorage.getItem('permissions') || '[]')
  const isAllowedTo = (requestedPermission: AvailablePermissions) => {
    return loadPermissions()?.indexOf(requestedPermission) >= 0
  }
  return {
    isAllowedTo: isAllowedTo,
    isAllowedToAny: (atLeastOnePermission: AvailablePermissions[]) => {
      const foundPermission = atLeastOnePermission?.find((permission) => {
        return isAllowedTo(permission)
      })
      return atLeastOnePermission.length == 0 || foundPermission != undefined
    },
  }
}
