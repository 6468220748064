import * as React from 'react'
import { AppBar, AppBarProps } from 'react-admin'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  logo: {
    height: '25px',
  },
})

const KlisstAppBar = (props: AppBarProps) => {
  const classes = useStyles()
  return (
    <AppBar {...props}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <img src="/KlisstLogo.png" className={classes.logo} />
      <span className={classes.spacer} />
    </AppBar>
  )
}

export default KlisstAppBar
