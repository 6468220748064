export const LOAD_KLISST_CONFIG = 'KLISST_CONFIG/FETCH'
export const LOAD_KLISST_CONFIG_SUCCESS = 'KLISST_CONFIG/FETCHED'

export interface LoadConfigAction {
  type: typeof LOAD_KLISST_CONFIG
}

export interface LoadedConfigAction {
  type: typeof LOAD_KLISST_CONFIG_SUCCESS
  payload: object
}

export const loadConfig = (): LoadConfigAction => ({
  type: LOAD_KLISST_CONFIG,
})

export const loadedConfig = (config: object): LoadedConfigAction => ({
  type: LOAD_KLISST_CONFIG_SUCCESS,
  payload: { ...config },
})
