// Auth Provider
import { FirebaseAuthProvider } from 'react-admin-firebase'
import { PermissionsForActions } from '../klisstConfig/PermissionsService'

// We need require for dynamic imports
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { FIREBASE_CONFIG } = require(`../config/${
  process.env.REACT_APP_ENV || 'development'
}`)

const firebaseProvider = FirebaseAuthProvider(FIREBASE_CONFIG, {})
export default {
  login: firebaseProvider.login,
  logout: (params: any) => {
    localStorage.removeItem('permissions')
    return firebaseProvider.logout(params)
  },
  checkError: firebaseProvider.checkError,
  checkAuth: firebaseProvider.checkAuth,
  getPermissions: () => {
    return Promise.resolve(PermissionsForActions())
  },
}
