import { Avatar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { UserSummary } from '../../types/api'

type UserSummaryInListProps = {
  user: UserSummary
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    flex: 1,
    margin: '0 0.5em 0 0',
    height: '55px',
    width: '55px',
  },
  content: {
    lineHeight: '0.6em',
  },
  name: {
    fontWeight: 'bold',
  },
  handler: {
    color: theme.palette.primary.main,
  },
}))

const UserSummaryInList = ({ user }: UserSummaryInListProps) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Avatar src={user.avatar} className={classes.avatar} />
      <div className={classes.content}>
        <Typography className={classes.name}>{user.displayName}</Typography>
        <Typography className={classes.handler}>@{user.handler}</Typography>
      </div>
    </div>
  )
}

export default UserSummaryInList
