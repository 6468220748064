import * as React from 'react'
import {
  useNotify,
  useTranslate,
  SimpleForm,
  TextInput,
  Create,
  SelectInput,
} from 'react-admin'
import { useState } from 'react'

const SOURCE_CHAT = 'openAIChat'
const SOURCE_CHAT_EXTRA = 'openAIChatWithExtraMetadata'
const SOURCE_CHAT_EXTRA_WEB = 'openAIChatWithWebsiteMetadata'

const AutomaticKlisstGeneration = () => {
  const t = useTranslate()
  const notify = useNotify()
  const [klisstPermalink, setKlisstPermalink] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const onSuccess = (response?: any) => {
    notify(t('resources.klisstGeneration.created'))
    setKlisstPermalink(response?.data?.permalink)
    setErrorMessage('')
  }

  const onFailure = (error?: any) => {
    notify(t('resources.klisstGeneration.createError'))
    setErrorMessage(error.message)
    setKlisstPermalink('')
  }

  let result
  if (klisstPermalink) {
    result = (
      <div style={{ color: 'green', width: '100%' }}>
        {t('resources.klisstGeneration.created')}
        {': '}
        <a href={klisstPermalink} target="_blank" rel="noreferrer">
          {klisstPermalink}
        </a>
      </div>
    )
  }
  if (errorMessage) {
    result = (
      <div style={{ color: 'red', width: '100%' }}>
        {t('resources.klisstGeneration.createError')}: {errorMessage}
      </div>
    )
  }

  return (
    <Create
      title={t('resources.klisstGeneration.name')}
      basePath="generatedContent/klisstByQuery"
      resource="generatedContent/klisstByQuery"
      onSuccess={onSuccess}
      onFailure={onFailure}
    >
      <SimpleForm mutationMode="pessimistic">
        <TextInput
          source="title"
          label={t('resources.klisstGeneration.titleLabel')}
          isRequired={true}
          fullWidth={true}
        />
        <TextInput
          source="onBehalfOfUsername"
          label={t('resources.klisstGeneration.usernameLabel')}
          isRequired={true}
        />
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <SelectInput
            source="source"
            choices={[
              {
                id: SOURCE_CHAT_EXTRA,
                name: 'resources.klisstGeneration.typeChatExtra',
              },
              {
                id: SOURCE_CHAT_EXTRA_WEB,
                name: 'resources.klisstGeneration.typeChatExtraWeb',
              },
              { id: SOURCE_CHAT, name: 'resources.klisstGeneration.typeChat' },
            ]}
            label=""
            defaultValue={SOURCE_CHAT_EXTRA}
            style={{ width: '400px', marginRight: '20px' }}
          />
          <TextInput
            source="instructions"
            label={t('resources.klisstGeneration.instructionsLabel')}
            isRequired={false}
            style={{ width: '600px', marginRight: '20px' }}
          />
          <TextInput
            source="context"
            label={t('resources.klisstGeneration.contextLabel')}
            placeholder={t('resources.klisstGeneration.contextPlaceholder')}
            isRequired={false}
            style={{ width: '300px', marginRight: '20px' }}
          />
        </div>
        {result}
      </SimpleForm>
    </Create>
  )
}

export default AutomaticKlisstGeneration
