import { takeEvery, put, call } from 'redux-saga/effects'
import dataProvider from '../http/DataProvider'
import { LOAD_KLISST_CONFIG, loadedConfig } from './loadConfigActions'
import { GetOneResult } from 'ra-core'

export default function* loadConfigSaga() {
  yield takeEvery(LOAD_KLISST_CONFIG, function* () {
    try {
      const response: GetOneResult = yield call(dataProvider.getOne, 'config', {
        id: '',
      })
      localStorage.setItem(
        'permissions',
        JSON.stringify(response.data.permissions || []),
      )
      yield put(loadedConfig(response.data))
    } catch (e) {
      return
    }
  })
}
