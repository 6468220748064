import polyglotI18nProvider from 'ra-i18n-polyglot'
import esMessages from './es'

export default polyglotI18nProvider((locale: string) => {
  let messages: any
  switch (locale) {
    case 'es': {
      messages = esMessages
      break
    }
    default: {
      messages = esMessages
      break
    }
  }
  return messages
})
