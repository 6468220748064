import * as React from 'react'
import { Card, CardContent } from '@material-ui/core'
import { Title, useTranslate } from 'react-admin'
import RefreshPropertiesButton from '../components/RefreshPropertiesButton'

const Management = () => {
  const translate = useTranslate()
  return (
    <Card>
      <Title title={translate('resources.management.name')} />
      <CardContent>
        <RefreshPropertiesButton />
      </CardContent>
    </Card>
  )
}

export default Management
