import { useQueryWithStore, useTranslate } from 'react-admin'
import KlisstCounterWIthIcon from '../components/KlisstCounterWIthIcon'
import { CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DefaultIcon from '@material-ui/icons/Speed'
import { FunctionComponent } from 'react'

const useStyles = makeStyles((theme) => {
  return {
    error: {
      color: theme.palette.error.main,
    },
  }
})

type DashboardCounterProps = {
  id: string
  label: string
  linkTo?: string
  icon: FunctionComponent
}

const DashboardCounter = ({
  id,
  label,
  linkTo,
  icon = DefaultIcon,
}: DashboardCounterProps) => {
  const { loaded, error, data } = useQueryWithStore({
    type: 'getOne',
    resource: 'dashboard',
    payload: { id: id },
  })
  const classes = useStyles()

  const translate = useTranslate()
  const fallbackValue = error ? (
    <Typography component="p" variant="h6" className={classes.error}>
      ----
    </Typography>
  ) : (
    <CircularProgress />
  )

  const value = loaded ? data?.value.toString() : fallbackValue // toString is required otherwise 0 won't be shown :(
  return (
    <KlisstCounterWIthIcon
      icon={icon}
      to={linkTo || '#'}
      title={translate(label)}
      subtitle={value}
    />
  )
}

export default DashboardCounter
