import * as React from 'react'
import {
  ChipField,
  CreateButton,
  Datagrid,
  DateField,
  DeleteButton,
  FunctionField,
  List,
  ListProps,
  Pagination,
  PaginationProps,
  TextField,
  TopToolbar,
  usePermissions,
} from 'react-admin'
import { Avatar, makeStyles } from '@material-ui/core'
import StopIcon from '@material-ui/icons/Stop'
import DeleteIcon from '@material-ui/icons/Delete'
import { unescape } from 'lodash'

export type KlisstBulkGeneration = {
  id: string
  createdAt: string
  status: string
  author: object
  description: string
  stopRequested?: string
  finished?: string
  numberOfRequestedKlissts: number
  numberOfInvalidRequestedKlissts: number
  csvContent: string
  csvFailed: string
  generatedKlissts: number
  failedDuringGeneration: number
  progressPercentage: number
}

const KlisstBulkGenerationPagination = (props: PaginationProps) => (
  <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />
)

const getDurationInHours = (start: Date, end: Date) =>
  ((end.getTime() - start.getTime()) / 1000 / 60 / 60).toFixed(2)

const useStyles = makeStyles(() => ({
  authors: {
    display: 'flex',
    alignItems: 'center',
  },
  avatars: {
    display: 'flex',
    marginLeft: '1em',
  },
  avatar: {
    height: '32px',
    width: '32px',
    border: '2px solid #FFF',
    '&:not(:last-child)': {
      marginRight: '-20px',
      opacity: 0.75,
    },
    '&:hover': {
      opacity: 1,
    },
  },
}))

const ListActions = () => {
  return (
    <TopToolbar>
      <CreateButton />
    </TopToolbar>
  )
}

const downloadableContent = (fileId: string, content: string): string => {
  let asBase64 = window.btoa('')
  try {
    asBase64 = window.btoa(unescape(content))
  } catch (e) {
    console.error(`Can not generate a downloadable the csv file [${fileId}]`, e)
  }
  return `data:application/octet-stream;charset=utf-16le;base64,${asBase64}`
}

export const KlisstBulkGenerationList = (props: ListProps) => {
  const { permissions } = usePermissions()
  const classes = useStyles()
  return (
    <List
      {...props}
      actions={<ListActions />}
      title="KlisstBulkGeneration"
      sort={{ field: 'createdAt', order: 'DESC' }}
      pagination={<KlisstBulkGenerationPagination />}
    >
      <Datagrid isRowSelectable={() => false}>
        <TextField source="id" />
        <TextField label="Description" source="description" />
        <FunctionField
          label="Author"
          sortable={false}
          render={(line: any) => {
            const user = line.author
            return (
              <Avatar
                key={user?.id}
                src={user?.avatarUrl}
                title={user?.username}
                className={classes.avatar}
              />
            )
          }}
        />
        <FunctionField
          label="Content"
          render={(line: any) => {
            return (
              <a
                href={downloadableContent(line.id, line.csvContent)}
                download={`${line.description}.csv`}
              >
                Uploaded
              </a>
            )
          }}
        />
        <FunctionField
          label="Failed"
          render={(line: any) => {
            return line.csvFailed ? (
              <a
                href={downloadableContent(line.id, line.csvFailed)}
                download={`${line.description}_failed.csv`}
              >
                Failed
              </a>
            ) : (
              '-'
            )
          }}
        />
        <DateField source="createdAt" showTime={true} />
        <FunctionField
          label="duration"
          sortable={false}
          render={(line: any) => {
            const { status, finished, stopRequested } = line
            let end: Date | undefined
            switch (status) {
              case 'finished':
                end = finished && new Date(finished)
                break
              case 'stopped':
                end = stopRequested && new Date(stopRequested)
                break
              case 'running':
                end = new Date()
                break
            }
            return end
              ? `${getDurationInHours(new Date(line.createdAt), end)}h`
              : '-'
          }}
        />
        <ChipField source="status" />
        <TextField source="numberOfRequestedKlissts" />
        <TextField source="numberOfInvalidRequestedKlissts" />
        <FunctionField
          label="progressPercentage"
          sortable={false}
          render={(line: any) => {
            return `${line.progressPercentage.toFixed(2)}%`
          }}
        />
        <TextField source="generatedKlissts" />
        <TextField source="failedDuringGeneration" />

        {permissions?.isAllowedTo('stopKlisstBulkGeneration') && (
          <FunctionField
            render={(line: any) => {
              const status = line.status
              return status === 'running' || status === 'waiting' ? (
                <DeleteButton
                  icon={<StopIcon />}
                  label="stop"
                  mutationMode="pessimistic"
                  record={line}
                />
              ) : (
                <DeleteButton
                  icon={<DeleteIcon />}
                  label="eliminar"
                  mutationMode="pessimistic"
                  record={line}
                />
              )
            }}
          />
        )}
      </Datagrid>
    </List>
  )
}
