import { get } from 'lodash'
import { useRecordContext, Link } from 'react-admin'
import { ReactElement } from 'react'
import * as React from 'react'
import {
  InjectedFieldProps,
  PublicFieldProps,
} from 'ra-ui-materialui/src/field/types'
import Button from '@material-ui/core/Button'
import ImageEye from '@material-ui/icons/RemoveRedEye'
import { stringify } from 'query-string'

/**
 * Button with a link to another section with filter
 * @param props
 * @constructor
 */

export interface ExternalLinkButtonProps
  extends PublicFieldProps,
    InjectedFieldProps {
  basePath: string
  filterKey: string
  filterValueSource: string
  // "label" is automatically added as title of the column, and I wasn't able to override it
  linkLabel?: string
  icon?: ReactElement
}

const ExternalLinkButton = (props: ExternalLinkButtonProps) => {
  const {
    basePath,
    filterKey,
    filterValueSource,
    linkLabel = 'show',
    icon = <ImageEye />,
    ...rest
  } = props
  delete (rest as any).label
  const record = useRecordContext(props)

  const filterValue = get(record, filterValueSource)
  return (
    <Button
      size="small"
      color="primary"
      // We need href or component + to for the link to work
      component={Link}
      to={{
        pathname: basePath,
        search: stringify({
          page: 1,
          perPage: 25,
          filter: JSON.stringify({ [filterKey]: filterValue }),
        }),
      }}
      onClick={(e) => e.stopPropagation()}
      {...(rest as any)}
    >
      {icon} &nbsp; {linkLabel}
    </Button>
  )
}

export default ExternalLinkButton
