import { DataProvider, fetchUtils } from 'react-admin'
import jsonDataProvider from 'ra-data-json-server'
import { Options } from 'ra-core/lib/dataProvider/fetch'
import { FireUser } from 'react-admin-firebase/src/misc/firebase-models'
import authProvider from './AuthProvider'

// We need require for dynamic imports
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { SERVER_URL } = require(`../config/${
  process.env.REACT_APP_ENV || 'development'
}`)

// Data provider
const httpClient = async (url: string, options: Options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }

  // @ts-ignore
  const user: FireUser = await authProvider.checkAuth({})
  ;(options.headers as Headers).set(
    'Authorization',
    `Bearer ${await user.getIdToken()}`,
  )
  return fetchUtils.fetchJson(url, options)
}

const apiUrl = `${SERVER_URL}/admin`

const dataProvider: DataProvider = jsonDataProvider(apiUrl, httpClient)
// So that we get the whole resource, not just the id
dataProvider.create = (resource, params) =>
  httpClient(`${apiUrl}/${resource}`, {
    method: 'POST',
    body: JSON.stringify(params.data),
  }).then(({ json }) => ({ data: json }))

export default dataProvider
